<template>
  <CRow>
    <CCol col="12" xl="8">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Groups
          </CCardHeader>
          <CCardBody>
            <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
            <CDataTable hover striped :items="items" :fields="fields" :items-per-page="5" pagination>
              <template #status="{item}">
                <td>
                  <CBadge :color="statuses[item._status].color">{{ statuses[item._status].name }}</CBadge>
                </td>
              </template>
              <template #show="{item}">
                <td>
                  <CButton color="secondary" @click="showGroup(item.id)">Show</CButton>
                </td>
              </template>
              <template #edit="{item}">
                <td>
                  <CButton color="primary" @click="editGroup(item.id)">Edit</CButton>
                </td>
              </template>
              <template #delete="{item}">
                <td>
                  <CButton color="danger" @click="deleteGroup(item.id)">Delete</CButton>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'

export default {
  name: 'Groups',
  mixins: [viewMixin],
  data: () => {
    return {
      items: [],
      fields: ['id', 'name', 'description', '_roles', 'status', 'show', 'edit', 'delete'],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,

      statuses: {
        0: { name: 'Inactive', color: 'success' },
        1: { name: 'Active', color: 'secondary' }
      }
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  methods: {
    groupLink(id) {
      return `groups/${id.toString()}`
    },
    editLink(id) {
      return `groups/${id.toString()}/edit`
    },
    showGroup(id) {
      const groupLink = this.groupLink(id)
      this.$router.push({ path: groupLink })
    },
    editGroup(id) {
      const editLink = this.editLink(id)
      this.$router.push({ path: editLink })
    },
    deleteGroup(id) {
      console.log(id)
      this.$http
        .delete('admin/groups', { id })
        .then(() => {
          this.message = 'Successfully deleted group.'
          this.showAlert()
          this.getGroups()
        })
        .catch(error => {
          console.error(error)
          this.$router.push({ path: '/login' })
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    getGroups() {
      this.$http
        .get('admin/groups')
        .then(response => {
          this.items = response.data.groups
          this.you = response.data.you
        })
        .catch(error => {
          console.error(error)
          this.$router.push({ path: '/login' })
        })
    }
  },
  mounted() {
    this.getGroups()
  }
}
</script>
